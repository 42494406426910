import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {Collapse} from 'react-bootstrap';

import useWebSocket, {ReadyState} from 'react-use-websocket';

import "./css/sticky-footer.css"

let WS_API_URL = "ws://127.0.0.1:8000/ws/curator_socket/";
if (process.env.REACT_APP_BACKEND_WS_SERVER) {
	WS_API_URL = process.env.REACT_APP_BACKEND_WS_SERVER;
} else {
	WS_API_URL = "ws://127.0.0.1:8000/ws/curator_socket/";
}


export const StickyFooter = ({setLastSocketCommandReceived}) => {
	const [messageHistory, setMessageHistory] = useState([]);
	const [reversedMessageHistory, setReversedMessageHistory] = useState([]);
	const [reconnectAttempts, setReconnectAttempts] = useState(0);
	const [lastDisplayMessage, setLastDisplayMessage] = useState("");
	const [expanded, setExpanded] = useState(false);


	const {lastJsonMessage, readyState} = useWebSocket(WS_API_URL, {
		reconnectAttempts: 1000, // number of reconnect attempts
		reconnectInterval: 3000, // time (in ms) between reconnect attempts
		shouldReconnect: () => true,
	});


	const handleToggle = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		if (readyState === 3) {
			setReconnectAttempts(reconnectAttempts + 1);
		}
	}, [readyState]);

	useEffect(() => {
		const commandMessages = ["reviews-ready-cmd"]
		if (commandMessages.includes(lastJsonMessage?.message)) {
			setLastSocketCommandReceived(lastJsonMessage?.message)
		} else {
			setLastDisplayMessage(lastJsonMessage?.message)

			let newMessageHistory = [...messageHistory]
			newMessageHistory.push(lastJsonMessage?.message);
			if (newMessageHistory.length > 15) {
				newMessageHistory.shift();
			}
			setMessageHistory(newMessageHistory)
			let reversedValues = [...messageHistory];
			reversedValues.reverse().slice(1)
			setReversedMessageHistory(reversedValues)
		}


	}, [lastJsonMessage, setLastSocketCommandReceived, setMessageHistory, setReversedMessageHistory]);


	const connectionStatus = {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Ready',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	}[readyState];

	const getStatusMessage = () => {
		if (readyState === 3) {
			return "Connection lost. Attempting to reconnect..."
		}
		return `DB Status: ${connectionStatus} - Last Message: ${lastDisplayMessage || 'None'}`
	}

	return (
		<footer className="sticky-footer">
			<Container className="text-center">
				{/*<p className="text-muted">{text ? text : "© 2023 My Company"}</p>*/}
				<span onClick={handleToggle} className="text-muted">{getStatusMessage()}</span>
				<Collapse className="messages-container" in={expanded}>
					<ol className="messages-list">
						{reversedMessageHistory.map((item, index) => (
							<ul key={index}>{item}</ul>
						))}
					</ol>
				</Collapse>
			</Container>
		</footer>
	);
};
