import React from "react";
import {usePromiseTracker} from "react-promise-tracker";
import {RotatingLines, MutatingDots} from "react-loader-spinner";
import "./Spinner.css";

export const Spinner = (props) => {
	const {promiseInProgress} = usePromiseTracker({area: props.area, delay: 0});
	return (
		<>
			{promiseInProgress && (
				<div className="spinner">
					<MutatingDots
						height={String(100 * (props.scale ? props.scale : 1))}
						width={String(100 * (props.scale ? props.scale : 1))}
						color="#04A9F5"
						secondaryColor='#04A9F5'
						radius={String(12.5 * (props.scale ? props.scale : 1))}
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			)}
		</>
	);
};


export const Lines = (props) => {
	const {promiseInProgress} = usePromiseTracker({area: props.area, delay: 0});
	return (
		<>
			{promiseInProgress && (
				<RotatingLines
					strokeColor="white"
					strokeWidth="3"
					animationDuration="0.75"
					width="24"
					visible={true}
				/>
			)}
		</>
	);
};

