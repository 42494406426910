import React, {useContext, useEffect, useRef, useState} from 'react';

import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';
import {StickyFooter} from "../../components/StickyFooter/StickyFooter";

import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import {ConfigContext} from '../../contexts/ConfigContext';
import * as actionType from '../../store/actions';

const AdminLayout = ({children}) => {
	const windowSize = useWindowSize();
	const ref = useRef();
	const configContext = useContext(ConfigContext);

	const {collapseMenu} = configContext.state;
	const {dispatch} = configContext;

	const [lastSocketCommandReceived, setLastSocketCommandReceived] = useState("")

	useOutsideClick(ref, () => {
		if (collapseMenu) {
			dispatch({type: actionType.COLLAPSE_MENU});
		}
	});

	useEffect(() => {
		if (windowSize.width > 992 && windowSize.width <= 1024) {
			dispatch({type: actionType.COLLAPSE_MENU});
		}
	}, [dispatch, windowSize]);

	let mainClass = ['pcoded-wrapper'];

	let common = (
		<React.Fragment>
			<Navigation/>
		</React.Fragment>
	);

	let mainContainer = (
		<React.Fragment>
			<NavBar/>
			<div className="pcoded-main-container">
				<div className={mainClass.join(' ')}>
					<div className="pcoded-content">
						<div className="pcoded-inner-content">
							<Breadcrumb/>
							{/*{children}*/}
							{/* Pass props to children components */}
							{React.Children.map(children, (child) =>
								React.cloneElement(child, {
									lastSocketCommandReceived,
									setLastSocketCommandReceived,
								})
							)}
						</div>
					</div>
				</div>
			</div>
			<StickyFooter setLastSocketCommandReceived={setLastSocketCommandReceived}/>
		</React.Fragment>
	);

	if (windowSize.width < 992) {
		let outSideClass = ['nav-outside'];
		if (collapseMenu) {
			outSideClass = [...outSideClass, 'mob-backdrop'];
		}

		common = (
			<div className={outSideClass.join(' ')} ref={ref}>
				{common}
			</div>
		);

		// mainContainer = (
		//     <div className="pcoded-outside" onClick={() => mobileOutClickHandler}>
		//         {mainContainer}
		//     </div>
		// );
	}

	return (
		<React.Fragment>
			{common}
			{mainContainer}
		</React.Fragment>
	);
};

export default AdminLayout;
