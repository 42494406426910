import axios, {AxiosInstance} from 'axios'

const instance: AxiosInstance = axios.create({
	baseURL: process.env.NEXT_PUBLIC_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json',
	},
})

export default instance