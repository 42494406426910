import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import React from 'react';
// import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {ConfigProvider} from './contexts/ConfigContext';
import {PersistGate} from 'redux-persist/integration/react';
import {Spinner} from "./components/Spinner"

import './index.scss';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import {store, persister} from './store';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

let root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// <React.StrictMode>
		<Provider store={store}>
			<ConfigProvider>
				<PersistGate loading={null} persistor={persister}>
					<App/>
					<Spinner/>
				</PersistGate>
			</ConfigProvider>
		</Provider>
	// </React.StrictMode>,
);

//
// ReactDOM.render(
// 	<Provider store={store}>
// 		<ConfigProvider>
// 			<PersistGate loading={null} persistor={persister}>
// 				<App/>
// 				<Spinner/>
// 			</PersistGate>
// 		</ConfigProvider>
// 	</Provider>,
// 	document.getElementById('root')
// );

reportWebVitals();
