import React, {Suspense, Fragment, lazy} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import AuthorGuard from "./components/Auth/AuthorGuard";
import DevGuard from "./components/Auth/DevGuard";
import StaffGuard from "./components/Auth/StaffGuard";

import {BASE_URL} from './config/constant';

export const renderRoutes = (routes = []) => (
	<Suspense fallback={<Loader/>}>
		<Switch>
			{routes.map((route, i) => {
				const Guard = route.guard || Fragment;
				const Layout = route.layout || Fragment;
				const Component = route.component;

				return (
					<Route
						key={i}
						path={route.path}
						exact={route.exact}
						render={(props) => (
							<Guard>
								<Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
							</Guard>
						)}
					/>
				);
			})}
		</Switch>
	</Suspense>
);

const routes = [
	{
		exact: true,
		guard: GuestGuard,
		path: '/auth/signin',
		component: lazy(() => import('./views/auth/signin/SignIn1'))
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/auth/signin-2',
		component: lazy(() => import('./views/auth/signin/SignIn2'))
	},
	{
		exact: true,
		path: '/app/dashboard/default',
		layout: AdminLayout,
		guard: AuthGuard,
		component: lazy(() => import('./views/dashboard/DashDefault'))
	},
	{
		exact: true,
		path: '/basic/button',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/ui-elements/basic/BasicButton'))
	},
	{
		exact: true,
		path: '/basic/badges',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
	},
	{
		exact: true,
		path: '/basic/breadcrumb',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
	},
	{
		exact: true,
		path: '/basic/collapse',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
	},
	{
		exact: true,
		path: '/basic/tabs-pills',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
	},
	{
		exact: true,
		path: '/basic/typography',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
	},

	{
		exact: true,
		path: '/forms/form-basic',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/forms/FormsElements'))
	},
	{
		exact: true,
		path: '/tables/bootstrap',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/tables/BootstrapTable'))
	},

	{
		exact: true,
		path: '/charts/nvd3',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/charts/nvd3-chart'))
	},
	{
		exact: true,
		path: '/maps/google-map',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/maps/GoogleMaps'))
	},

	{
		exact: true,
		path: '/sample-page',
		layout: AdminLayout,
		guard: DevGuard,
		component: lazy(() => import('./views/extra/SamplePage'))
	},
	{
		exact: true,
		path: '/curator',
		layout: AdminLayout,
		guard: AuthorGuard,
		component: lazy(() => import('./views/curator/CuratorTabs'))
	},
	{
		exact: true,
		path: '/curator_reviews',
		layout: AdminLayout,
		guard: AuthorGuard,
		component: lazy(() => import('./views/curator/AllReviewsTabs'))
	},
	{
		exact: true,
		path: '/stats',
		layout: AdminLayout,
		guard: AuthorGuard,
		component: lazy(() => import('./views/curator/StatsPage'))
	},
	{
		exact: true,
		path: '/cluster_control',
		layout: AdminLayout,
		guard: AuthorGuard,
		component: lazy(() => import('./views/curator/CuratorClusterControl'))
	},
	{
		exact: true,
		path: '/review_model',
		layout: AdminLayout,
		guard: AuthorGuard,
		component: lazy(() => import('./views/curator/CuratorModelApp'))
	},

	{
		exact: true,
		path: '/phrases',
		layout: AdminLayout,
		guard: StaffGuard,
		component: lazy(() => import('./views/curator/CuratorTabs'))
	},
	{
	    path: '*',
	    exact: true,
		layout: AdminLayout,
	    component: () => <Redirect to={BASE_URL} />
	},
];

export default routes;
