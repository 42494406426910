import {useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';
import {persistStore} from 'redux-persist';
import {configureStore} from '@reduxjs/toolkit';

import reducers from './reducers';

const store = configureStore({
	reducer: reducers,
	middleware: getDefaultMiddleware => getDefaultMiddleware({
		serializableCheck: false
	}), // Used to get around the error -  A non-serializable value was detected in an action, in the path: `register`. Value: [Function: register]
	devTools: true
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

const persister = persistStore(store);

export {store, persister};
