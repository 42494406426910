
export const isUserAllowed = (allowed_user_groups, user) => {
    switch (allowed_user_groups) {
        case "active":
            return user?.is_active;
        case "dev":
            return user?.is_dev;
        case "author":
            return user?.is_author;
        case "staff":
            return user?.is_staff;
        default:
            return true;
    }
}
