import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../config/constant';

const StaffGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { user } = account;

    if (!user?.is_staff) {
        return <Redirect to={BASE_URL} />;
    }

    return children;
};

export default StaffGuard;
