import React from 'react';
import { ListGroup } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

import NavGroup from './NavGroup';

import {isUserAllowed} from "./NavUtils";

const NavContent = ({ navigation }) => {
    const user = useSelector((state) => state.account.user);

    const navItems = navigation.map((item) => {
        if (!isUserAllowed(item.allowed_user_groups, user)) {
            return false;
        }
        switch (item.type) {
            case 'group':
                return <NavGroup layout="vertical" key={'nav-group-' + item.id} group={item} user={user}/>;
            default:
                return false;
        }
    });

    let mainContent = '';
    mainContent = (
        <div className="navbar-content datta-scroll">
            <PerfectScrollbar>
                <ListGroup variant="flush" as="ul" bsPrefix=" " className="nav pcoded-inner-navbar" id="nav-ps-next">
                    {navItems}
                </ListGroup>
            </PerfectScrollbar>
        </div>
    );

    return <React.Fragment>{mainContent}</React.Fragment>;
};

export default NavContent;
